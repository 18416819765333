export const Colors = {
    aqp_deep_sea: '#073352',
    aqp_sundown: '#DF783A',
    aqp_sundown_dark: '#A84A0A',
    aqp_coastal_water: '#298F8D',
    aqp_white: '#FEFEFC',
    aqp_gray: '#706F75',
    aqp_error: '#c30000',
    aqp_warning: '#c30000',
    aqp_bg_light_gray: '#f4f4f4',
    aqp_lightest_gray: '#fafafa',
    aqp_bg_lighter_gray: '#fbfbfb',
    aqp_light_cyan_blue: '#4ca7fe',
    aqp_parrot_green: '#62942f',
    aqp_lemon: '#decb2e',
  };
  
  const ThemeStyles = {
    primaryColor: {
      color: Colors.aqp_deep_sea,
      rgb: '7, 51, 82',
    },
    secondaryColor: {
      color: Colors.aqp_coastal_water,
    },
    actionColor: {
      color: Colors.aqp_sundown,
      rgb: '223, 120, 58',
    },
    iconStyle: {
      maxHeight: 36,
      padding: 0,
      backgroundColor: Colors.aqp_white,
      marginRight: 10,
    },
    iconHoveredStyle: {
      backgroundColor: Colors.aqp_coastal_water,
    },
    secondaryIconStyle: {
      maxHeight: 36,
      padding: 0,
      color: Colors.aqp_white,
      backgroundColor: Colors.aqp_coastal_water,
      marginRight: 10,
    },
  };
  
  export default ThemeStyles;
  