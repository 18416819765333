import styled from 'styled-components';
import { Colors } from './../../aquaplot/colors';

export const ParticlesJsDiv = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: ${Colors.aqp_deep_sea};
    z-index: -1;

    canvas{
        height: 100%;
        width: 100%;
    }
`