import React from 'react'
import { Link } from 'gatsby'

import Logo from './../../AquaplotLogo';

import './../../legacy/_aqp-web.scss';
 
import {
    StyledNav,
    NavWrapper,
    StyledLink,
    NavLinks,
    NavTopLeft,
    GoToAppButton
} from './styledComponents';

const Navigation = () => (
    <NavWrapper>
        <StyledNav role="navigation">
            <NavTopLeft>
                <Link
                    to="/"
                    aria-label="aquaplot logo"
                >    
                    <Logo />
                </Link>
            </NavTopLeft>
        
            <NavLinks id="nav-links">
                <StyledLink to="/">         Platform    </StyledLink>
                <StyledLink to="/explorer"> Explorer    </StyledLink>
                <StyledLink to="/api">      API         </StyledLink>
                <GoToAppButton
                    href="https://app.aquaplot.com"
                >
                    Open App
                </GoToAppButton>
            </NavLinks>
        </StyledNav>
    </NavWrapper>
)

export default Navigation
