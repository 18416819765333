import React from 'react';
import AquaplotLogoSvg from './../../aquaplot/logo';
import {
    LogoWrapper
} from './styledComponents';

const AqpLogo = () => {
  return (
    <LogoWrapper>
      <AquaplotLogoSvg />
    </LogoWrapper>
  );
}

export default AqpLogo;
