import React from 'react'
import { Link } from 'gatsby'
import { Colors } from './../../../aquaplot/colors';

import {
    StyledFooter,
    CopyrightNotice,
    SectionTitle,
    SectionContainer,
    SectionWrapper,
    FooterContainer
} from './styledComponents';

var PolygonBackground = require('./../../../images/polyback.svg');


const Footer = () => (
    <div
        style={{
            width: '100%',
            zIndex: 1000,
            position: 'relative',
            minHeight: '100vH',
            backgroundColor: Colors.aqp_deep_sea,
        }}
    >
    <div 
        style={{
            width: '100%', 
            minHeight: '100%', 
            opacity: 0.8,
            backgroundImage: `url(${PolygonBackground})`,
            position: 'absolute',
            filter: 'saturate(30%) contrast(150%)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}
    />
    <StyledFooter id="aqp-footer">
        <FooterContainer id="footer-content">
            <SectionWrapper>
                <SectionContainer>
                    <SectionTitle>About</SectionTitle>
                    <Link to="/">Platform</Link>
                    <Link to="/company">Company</Link>
                    <Link to="/evoswarm">Routing Technology</Link>
                    <Link to="/contact">Contact</Link>
                </SectionContainer>
            </SectionWrapper>
            <SectionWrapper>
                <SectionContainer>
                    <SectionTitle>API</SectionTitle>
                    <Link to="/api#routing-api">Routing</Link>
                    <Link to="/api#ais-api">Satellite & Terrestrial AIS</Link>
                    <Link to="/api#weather">Weather</Link>
                    <Link to="/api/docs">API Documentation</Link>
                    <a href="https://status.aquaplot.com">Status Page</a>
                    <Link to="/api#pricing">Pricing</Link>
                </SectionContainer>
            </SectionWrapper>
            <SectionWrapper>
                <SectionContainer>
                    <SectionTitle>Explorer</SectionTitle>
                    <Link to="/explorer#distance-table">Distance Table</Link>
                    <Link to="/sea-distance-matrix">Distance Matrix</Link>
                    {/* <Link to="/explorer#ais-tracking">Vessel Tracking (AIS)</Link> */}
                    {/* <Link to="/explorer/voyage-estimation">Voyage Estimation</Link> */}
                    <Link to="/explorer#pricing">Pricing</Link>
                </SectionContainer>
            </SectionWrapper>
            <SectionWrapper>
                <SectionContainer>
                    <SectionTitle>Legal</SectionTitle>
                    <Link to="/imprint">Imprint</Link>
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link to="/tos">Terms of Service</Link>
                </SectionContainer>
            </SectionWrapper>
        </FooterContainer>

        <CopyrightNotice>
            Copyright &copy; {(new Date()).getFullYear()}. All rights reserved.
        </CopyrightNotice>
    </StyledFooter>
    </div>
)

export default Footer
