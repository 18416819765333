import styled from 'styled-components';
import ThemeStyles from './../../../aquaplot/colors';
import { Link } from 'gatsby';

export const StyledCard = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-shadow: 1px 1px 2px 0px rgba(${ThemeStyles.primaryColor.rgb}, 0.4);
    font-size: smaller;
    margin: 20px;

    * {
        max-width: 300px;
    }

    .platform-description{
        align-self: flex-start;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        padding-bottom: 15px;
        padding-right: 15px;
    }
`

export const IconWrapper = styled.div`
    padding: 30px;
    width: 120px;
    display: flex;
`

export const StyledLink = styled(Link)`
    &{
        flex-grow: 1;
    }
`

export const LinksContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
    align-items: flex-end;
`

export const DescriptionContainer = styled.div`
    align-self: flex-start;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 15px;
    padding-right: 15px;
`