import React from 'react';
import {
    StyledCard,
    IconWrapper,
    StyledLink,
    LinksContainer,
    DescriptionContainer
} from './styledComponents';


const Pure = ({
    aspect,
    description,
    icon,
    actionLinks,
    className
}) => {
    return (
        <StyledCard className={className}>
            <IconWrapper>
                { icon }
            </IconWrapper>
            <DescriptionContainer>
                <h3>{ aspect }</h3>
                <p>{ description }</p>
                <LinksContainer>
                    { 
                        actionLinks.map(({ to, label }, idx) => (
                            <StyledLink 
                                key={`link-${idx}`} 
                                to={to}
                            >
                                { label }
                            </StyledLink>
                        ))
                    }
                </LinksContainer>
            </DescriptionContainer>
        </StyledCard>
    )
}

export default Pure;