import styled from 'styled-components';
import { Colors } from './../../../aquaplot/colors';

export const StyledFooter = styled.footer`
    position: relative;
    color: $txt_bright;
    padding-top: 20px;
    padding-bottom: 2em;
    clear: both;
    bottom: 0%;
    font-size: .8em;
    max-width: 100%;
    user-select: none;
    width: 100%;
`

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    max-width: 900px;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-around;
    padding-left: 20px;
`

export const CopyrightNotice = styled.div`
    margin-top: 100px;
    text-align: center;
    padding-top: 2em;
    color: ${Colors.aqp_coastal_water};
`

export const SectionContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;

    margin: 0 auto;
    align-items: flex-start;
    justify-content: center;

    padding: 10px;

    a {
        color: white;
    }
`

export const SectionWrapper = styled.div`
    flex: 1 0 150px;
    max-width: 170px;
`

export const SectionTitle = styled.h3`
color: ${Colors.aqp_coastal_water};
    font-size: 1.5em;
    text-align: left;
`