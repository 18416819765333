import React from 'react';
import {
    StyledCard
} from './styledComponents';

const Pure = ({
    icon,
    value,
    metric
}) => {
    return (
        <StyledCard>
            <section>
                { icon } 
            </section>
            <h3 style={{marginTop: '1rem', color: 'white'}}>{value}</h3><span>{metric}</span>
        </StyledCard>
    )
}

export default Pure;