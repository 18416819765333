import React from 'react';
import {
    ExternalLink,
    Button,
    Label
} from './styledComponents';

const ActionButton = ({
    actionType,
    ...rest
}) => {
    switch (actionType) {
        case "button":
            return <Button {...rest}/>
        case "label":
            return <Label {...rest} />
        case "link":
        default:
            return <ExternalLink {...rest} />
    }
}

export default ActionButton;