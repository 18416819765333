import styled from 'styled-components';
import Theme, { Colors } from './../../aquaplot/colors';

export const ExternalLink = styled.a`
    color: ${Colors.aqp_white};
    background-color: rgba(${Theme.actionColor.rgb}, 0.8);
    border-radius: 5px;
    border: 1px solid ${Theme.actionColor.color};
    min-width: 100px;
    transition: all 300ms ease-in-out;
    user-select: none;

    white-space: nowrap;

    touch-action: manipulation;
    cursor: pointer;

    font-size: large;
    text-transform: uppercase;
    padding: .1em .75em;

    :hover{
        color: ${Theme.actionColor.color};
        background-color: transparent;
      
        a, a:hover, a:focus, a.focus{
          color: ${Theme.actionColor.color};
        }
    }
`

export const Button = styled.button`
    color: ${Colors.aqp_white};
    background-color: rgba(${Theme.actionColor.rgb}, 0.8);
    border-radius: 5px;
    border: 1px solid ${Theme.actionColor.color};
    min-width: 100px;
    transition: all 300ms ease-in-out;
    user-select: none;

    white-space: nowrap;

    touch-action: manipulation;
    cursor: pointer;

    font-size: large;
    text-transform: uppercase;
    padding: .1em .75em;
    
    :hover{
        color: ${Theme.actionColor.color};
        background-color: transparent;
      
        button, button:hover, button:focus, button.focus{
          color: ${Theme.actionColor.color};
        }
    }
`

export const Label = styled.label`
    color: ${Colors.aqp_white};
    background-color: rgba(${Theme.actionColor.rgb}, 0.8);
    border-radius: 5px;
    border: 1px solid ${Theme.actionColor.color};
    min-width: 100px;
    transition: all 300ms ease-in-out;

    user-select: none;

    white-space: nowrap;

    touch-action: manipulation;
    cursor: pointer;

    font-size: large;
    text-transform: uppercase;
    padding: .1em .75em;

    :hover{
        color: ${Theme.actionColor.color};
        background-color: transparent;
    
        label, label:hover, label:focus{
            color: ${Theme.actionColor.color};
        }
    }
`