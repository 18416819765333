import React from 'react';
import {
    StyledCard,
    IconWrapper
} from './styledComponents';


const Pure = ({
    stakeholder,
    benefit,
    icon
}) => {
    return (
        <StyledCard>
            <IconWrapper>
                { icon }
            </IconWrapper>
            <div className="platform-description">
                <h3>{ stakeholder }</h3>
                <p>{ benefit }</p>
            </div>
        </StyledCard>
    )
}

export default Pure;