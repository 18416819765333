import styled from 'styled-components';

export const StyledCard = styled.div`
    background-color: transparent;
    color: white;
    box-shadow: none;
    z-index: 10;
    text-align: center;
    padding: 1em;
    width: 200px;
    margin: 10px;
`