import styled from 'styled-components';
import { Colors } from './../../../aquaplot/colors';
import { Link } from 'gatsby'
import ActionButtonLink from './../../ActionButton';

export const GoToAppButton = styled(ActionButtonLink)`
    & {
        font-weight: 300;
    }
`

export const StyledNav = styled.nav`
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    width: 100%;
    background-color: ${Colors.aqp_deep_sea};
    max-width: 1024px;

    @media (min-width:768px){
        padding: 10px;
    }
`
export const NavWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: ${Colors.aqp_deep_sea};
`

export const StyledLink = styled(Link)`
    &{
        white-space: pre-line;
        text-align: right;
        font-weight: 300;

        align-self: center; 
        background-color: transparent;
        border: none;
        min-width: 0;

        text-transform: uppercase;
        color: #FEFEFC; 

        user-select: none;
        padding: .1em .75em;
        touch-action: manipulation;
        cursor: pointer;

        text-decoration: none;

        @media (max-width: 768px) {
            display: none;
        }
    }
`

export const NavLinks = styled.div`
    min-height: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: 15px;

    @media (max-width:768px){
        font-size: small;
    }

    @media (min-width:768px){
        justify-content: flex-end;
    }
`

export const NavTopLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    height: 50px;
`
