import styled from 'styled-components';
import { Colors } from './../../aquaplot/colors';

export const LogoWrapper = styled.div`
    min-height: 50px;
    margin-left: 25px;
    margin-right: 5px;
    display: flex;

    :hover{
        svg path{
            fill: ${Colors.aqp_sundown};
        }
    }
`