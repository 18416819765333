import React from 'react';
import {
    Header
} from './styledComponents';

const Pure = ({
    children,
    ...rest
}) => {
    return (
        <Header {...rest}>{ children }</Header>
    )
}

export default Pure;