//@flow
import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import {
    StyledImg,
    StyledSection,
    SupportedByTag,
    ImagesContainer
} from './styledComponents';

const EsaEfreSupport = () => (
    <StaticQuery
        query={graphql`
            query {
                esaBicImage: file(relativePath: { eq: "esa-logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                    }
                }
                }
                efreImage: file(relativePath: { eq: "leu-efre-logo-4c-schrift_seitlich-inpfade_300_hohe_aufloesung.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                    }
                }
                }
            }
        `}
        render={data => (
            <StyledSection>
                <SupportedByTag>supported by</SupportedByTag>
                <ImagesContainer>
                    <StyledImg 
                        fluid={data.efreImage.childImageSharp.fluid}
                        alt="European Structural and Investment Fund"
                        critical
                        fadeIn={false}
                    />
                    <StyledImg 
                        fluid={data.esaBicImage.childImageSharp.fluid}
                        alt="ESA Business Incubation Centre"
                        critical
                        fadeIn={false}
                    />
                </ImagesContainer>
            </StyledSection>
        )}
    />
    
)

export default EsaEfreSupport;