import React from 'react'
import { graphql } from "gatsby"
import Octicon, {
  Database, 
  DeviceDesktop, 
  Organization, 
  Globe, 
  Code, 
  Diff, 
  DeviceMobile
} from '@githubprimer/octicons-react'
import MetricCard from './../components/Cards/Metric';
import PlatformBenefitCard from './../components/Cards/PlatformBenefit';
import PlatformAspectCard from './../components/Cards/PlatformAspect';
import { CardsContainer } from './../components/Cards/styledComponents';
import EsaEfreSupport from '../components/EsaEfreSupport'
import SectionHeader from './../components/Header/Section';
import ParticlesJsDiv from './../components/ParticlesJs';
import HeaderGraphic from './../components/Landingpage/Graphic';
import { Header } from './../components/Landingpage/Header';
import { Colors } from './../aquaplot/colors';

import Layout from '../components/Layout'

const IndexPage = ({ data }) => (
  <Layout>
    <Header>
      <ParticlesJsDiv />
      <div className="landingpage-header-content">
        <HeaderGraphic className="landingpage-header-content-image" fluid={data.platformImage.childImageSharp.fluid} /> 
        <div className="landingpage-header-content-text">
          <h1>Connecting Maritime</h1>
          <p>
            A Platform for Data, Web Services and Applications. For Professionals in Maritime.
          </p>
        </div>
        <EsaEfreSupport />
      </div>
    </Header>
    <SectionHeader underline>The Aquaplot Platform</SectionHeader>

    <CardsContainer>
      <PlatformAspectCard 
        aspect="Data & API Services"
        description={`
          Sea distances, terrestrial and satellite AIS data, 
          weather data, forecasts and more from Aquaplot and 
          trusted 3rd parties. All available through a secure, 
          unified and easy to use RESTful web service.
        `}
        icon={<Octicon size="large" icon={Database}/>}
        actionLinks={
          [
            { to: '/api', label: 'Learn More' },
            { to: '/api/docs', label: 'Read API Docs' },
          ]
        }
      />

      <PlatformAspectCard 
        aspect="Web Applications"
        description={`
          A curated list of solutions, from basics such as 
          Distance Tables and Vessel Tracking to Voyage 
          Estimation, Vessel Management and more.
        `}
        icon={<Octicon size="large" icon={DeviceDesktop}/>}
        actionLinks={
          [
            { to: '/explorer', label: 'Learn More' },
          ]
        }
      />
    </CardsContainer>

    <div style={{
      display: 'flex',
      justifyContent: 'center',
      margin: '50px 0px',
      width: '100%',
      userSelect: 'none',
      position: 'relative',
      flexWrap: 'wrap',
      backgroundColor: Colors.aqp_deep_sea
    }}>
      <div style={{
        flex: '1 0 100%',
        color: 'white',
        zIndex: 100,
        padding: '10px 0px',
        textAlign: 'center'
      }}>As of March '19</div>
      <MetricCard 
        metric="USERS"
        value="12,000+"
        icon={<Octicon size="large" verticalAlign="middle" icon={Organization}/>}
      />
      <MetricCard 
        metric="COUNTRIES"
        value="130+"
        icon={<Octicon size="large" verticalAlign="middle" icon={Globe}/>}
      />
    </div>


      <SectionHeader underline>Benefits</SectionHeader>
      <CardsContainer>
        <PlatformBenefitCard 
          stakeholder="Professionals"
          benefit={`
            A central go-to place for all business needs. 
            Applications that work seamlessly together. 
            Pay for data once, use it everywhere.`
          }
          icon={<Octicon size="large" icon={Organization}/>}
        />
        
        <PlatformBenefitCard 
          stakeholder="Developers"
          benefit={`
            Infrastructure services and ready-made components 
            allow you to focus on delivering maximum business 
            value to your clients and go to market faster.`
          }
          icon={<Octicon size="large" icon={Code}/>}
        />

        <PlatformBenefitCard 
          stakeholder="Data Providers"
          benefit={`
            A great way to increase your brand awareness and monitize 
            your data with little to no investment needed.`
          }
          icon={<Octicon size="large" icon={Diff}/>}
        />

        <PlatformBenefitCard 
          stakeholder="Software Providers"
          benefit={`
            Immediate exposure to a broad and fast growing 
            user base.`
          }
          icon={<Octicon size="large" icon={DeviceMobile}/>}
        />
      </CardsContainer>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    platformImage: file(relativePath: { eq: "platform-notext.001.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    userBaseImage: file(relativePath: { eq: "user_base.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
