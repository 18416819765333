import styled from 'styled-components';

export const Header = styled.div`
    position: relative;
    color: white;
    width: 100%;
    min-height: calc(100vh - 50px);
    display: flex;

    *{
    user-select: none;
    }

    h1{
    text-transform: uppercase;
    }

    .landingpage-header-content{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    pointer-events: none;

    @media (max-width:425px){
        h1, p{
            text-align: center;
        }
    }

    .landingpage-header-content-image + .landingpage-header-content-text{
        max-width: 255px;
        text-align: left;
        min-height: unset;

        @media (min-width:768px){
        padding-right: 50px;
        }
    }

    .landingpage-header-content-text{
        text-align: center;
        z-index: 1;
        order: -1;
        width: 100%;
        min-height: 70%;
    }

    .landingpage-header-content-image{
        position: relative;
        z-index: 1;
    }

    align-items: center;

    }
`