import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from './../../aquaplot/colors';

export const DefaultHeader = styled.div`
    background-color: ${Colors.aqp_deep_sea};
    width: 100%;
    border-top: 1px solid ${Colors.aqp_coastal_water};

    h1{ 
        color: ${Colors.aqp_white};
    }
    text-align: center;
`

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    margin: 0px;
  }

  body {
    height: 100%;
    width: 100%;
    display: flex;
    color: ${Colors.aqp_gray};

    label{
        color: ${Colors.aqp_deep_sea};
    }
    
    a { 
        color: ${Colors.aqp_deep_sea}; 
        text-decoration: none;
    }
    
    a:hover, a:focus{
        text-decoration: none;
        color: ${Colors.aqp_sundown};
    }
    
    *::selection {
        background: ${Colors.aqp_coastal_water};
        color: ${Colors.aqp_white};
    }
    *::-moz-selection {
        background: ${Colors.aqp_coastal_water};
        color: ${Colors.aqp_white};
    }

    div {
        max-width: 100%;
    }
  }
`