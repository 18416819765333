import styled from 'styled-components';

export const Wrapper = styled.div`
    flex: 0 0 50%;
    alignSelf: stretch;
    marginBottom: auto;
    marginTop: auto;
    padding: 5vh 5vw 5vh 5vw;

    @media (max-width:500px){
        flex-grow: 1!important;
    }
`