import styled from 'styled-components';
import Img from "gatsby-image"

export const StyledImg = styled(Img)`
    && {
        max-width: 320px;
        min-width: 300px;
        flex: 1 1 auto;
        overflow: unset!important;
    }
`

export const StyledSection = styled.section`
    align-items: center;
    flex-direction: column;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    background-color: white;

    flex: 1 0 100%;

`

export const SupportedByTag = styled.div`
    width: 100%;
    font-size: small;
    text-align: center;
    color: darkslategray;
    padding: 1em;
`

export const ImagesContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;

    @media (min-width: 400px){
        div:not(:last-child) {
            margin-right: 25px;
        }
    }
    
`