import React from 'react';
import Img from "gatsby-image"
import {
    Wrapper
} from './styledComponents';

const Pure = ({
    fluid,
    ...rest
}) => {
    return (
        <Wrapper {...rest}>
          <Img 
            fluid={fluid}
            critical
            fadeIn={false}
          />
        </Wrapper>
    )
}

export default Pure;