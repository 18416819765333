import React from 'react';

const AquaplotLogoSvg = () => {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="-30 340 350 120" 
        xmlSpace="preserve"
        style={{
            fill: 'white',
            height: 35,
            alignSelf: 'center'
        }}
      >
        <path
          className="st0"
          d="M7.7,420.6c-3.2,4.7-8,7.1-14.4,7.1c-5.8,0-10.5-2-14.2-6.1c-3.6-4-5.3-8.9-5.3-14.8s1.8-10.8,5.3-14.8
            c3.7-4,8.5-6.1,14.6-6.1s10.8,2.4,14,7.2v-6.5h6.1v40.3H7.7L7.7,420.6L7.7,420.6z M-6,422.2c4.4,0,7.8-1.5,10.3-4.5
            c2.4-2.9,3.6-6.5,3.6-10.8c0-4.3-1.2-8-3.6-10.8c-2.5-3-6-4.5-10.3-4.5s-7.8,1.5-10.5,4.5c-2.5,2.9-3.8,6.5-3.8,10.8
            c0,4.3,1.3,8,3.8,10.8C-13.8,420.7-10.3,422.2-6,422.2z"
        />
        <path
          className="st0"
          d="M118.3,427.1v-6.3c-1.5,1.9-3.2,3.6-5.3,4.9c-2.1,1.3-4.5,2-7.2,2s-5-0.4-6.9-1.1c-1.9-0.8-3.6-1.9-5.1-3.4
            c-3.3-3.3-5-7.6-5-13v-23.5h6.1V410c0,3.7,1,6.7,3.1,8.9c2.1,2.2,4.8,3.3,8.2,3.3c3.4,0,6.3-1.1,8.6-3.4c2.4-2.3,3.6-5.3,3.6-8.9
            v-23.2h6.1V427L118.3,427.1L118.3,427.1z"/>
        <path
          className="st0"
          d="M166.8,420.6c-3.2,4.7-8,7.1-14.4,7.1c-5.8,0-10.5-2-14.2-6.1c-3.6-4-5.3-8.9-5.3-14.8s1.8-10.8,5.3-14.8
            c3.7-4,8.5-6.1,14.6-6.1s10.8,2.4,14,7.2v-6.5h6.1v40.3h-6.1V420.6z M153.1,422.2c4.4,0,7.8-1.5,10.3-4.5c2.4-2.9,3.6-6.5,3.6-10.8
            c0-4.3-1.2-8-3.6-10.8c-2.5-3-6-4.5-10.3-4.5s-7.8,1.5-10.5,4.5c-2.5,2.9-3.8,6.5-3.8,10.8c0,4.3,1.3,8,3.8,10.8
            C145.3,420.7,148.8,422.2,153.1,422.2z"/>
        <path
          className="st0"
          d="M189.8,393.2c3.2-4.7,8-7.1,14.4-7.1c5.8,0,10.5,2,14.1,6.1c3.6,4,5.4,8.9,5.4,14.8c0,5.9-1.8,10.8-5.4,14.8
            s-8.4,6.1-14.5,6.1s-10.8-2.4-14-7.2v22.5h-6.1v-56.3h6.1V393.2z M203.4,391.6c-4.4,0-7.8,1.5-10.3,4.5c-2.4,2.9-3.6,6.5-3.6,10.8
            c0,4.3,1.2,8,3.6,10.8c2.5,3,6,4.5,10.3,4.5s7.8-1.5,10.5-4.5c2.5-2.9,3.8-6.5,3.8-10.8c0-4.3-1.3-8-3.8-10.8
            C211.2,393.1,207.7,391.6,203.4,391.6z"/>
        <path className="st0" d="M238.1,427.1H232v-56.5h6.1V427.1z"/>
        <path
          className="st0"
          d="M266.5,386.1c6,0,10.9,2,14.7,6.1c3.6,3.9,5.3,8.8,5.3,14.8s-1.8,10.9-5.3,14.8c-3.8,4-8.7,6.1-14.7,6.1
            s-10.9-2-14.7-6.1c-3.6-3.9-5.3-8.8-5.3-14.8s1.8-10.9,5.3-14.8C255.6,388.1,260.5,386.1,266.5,386.1z M266.5,422.2
            c4.4,0,7.8-1.5,10.3-4.5c2.4-2.9,3.6-6.5,3.6-10.8c0-4.3-1.2-8-3.6-10.8c-2.5-3-6-4.5-10.3-4.5c-4.4,0-7.8,1.5-10.3,4.5
            c-2.4,2.9-3.6,6.5-3.6,10.8c0,4.3,1.2,8,3.6,10.8C258.7,420.7,262.1,422.2,266.5,422.2z"/>
        <path
          className="st0"
          d="M298.5,427.1v-35h-8.2v-5.3h8.2v-14.4h6.1v14.4h9.3v5.3h-9.3v35H298.5z"
        />
        <g>
          <path
            className="st0"
            d="M65,418.7c-2.6,2.6-6.2,4.2-10.2,4.2c-8,0-14.4-6.4-14.4-14.4c0-4,1.6-7.6,4.2-10.2l-4.1-4.1
              c-3.6,3.6-5.9,8.7-5.9,14.3c0,11.1,9,20.2,20.2,20.2c5.6,0,10.6-2.3,14.3-5.9L65,418.7z"
          />
          <path
            className="st0"
            d="M73.2,426.8c4.7-4.7,7.6-11.2,7.6-18.3c0-14.3-11.6-25.9-25.9-25.9c-7.2,0-13.6,2.9-18.3,7.6l4.1,4.1
              c3.6-3.6,8.7-5.9,14.3-5.9c11.1,0,20.2,9,20.2,20.2c0,5.6-2.3,10.6-5.9,14.3L73.2,426.8z"
          />
          <path
            className="st0"
            d="M83,436.7l-5.8-5.8l0,0l-4.1-4.1c-4.7,4.7-11.2,7.6-18.3,7.6c-14.3,0-25.9-11.6-25.9-25.9
              c0-7.2,2.9-13.6,7.6-18.3l-4.1-4.1c-5.7,5.7-9.3,13.6-9.3,22.4c0,17.5,14.2,31.7,31.7,31.7c6.7,0,12.8-2.1,17.9-5.6l6.2,6.2
              L83,436.7z"
          />
        </g>
      </svg>
    );
}

export default AquaplotLogoSvg;