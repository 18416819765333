import styled from 'styled-components';

export const Header = styled.h2`
    text-align: center; 
    border-bottom: ${props => props.underline ? '1px solid #000' : 'none'}; 
    border-bottom-width: thin;
    display: inline-block;
    width: unset;
    max-width: ${props => props.maxWidth || 'unset'};
    flex: ${props => props.flex || '0 0 auto'};
`